// import store from '@/store/index'

// function checkPermission (el, binding) {
//   const { value } = binding
//   const roles = store.getters['login/getRoles']

//   if (value && value instanceof Array) {
//     if (value.length > 0) {
//       const permissionRoles = value

//       const hasPermission = roles.some(role => {
//         return permissionRoles.includes(role)
//       })

//       if (!hasPermission) {
//         el.parentNode && el.parentNode.removeChild(el)
//       }
//     }
//   } else {
//     throw new Error('need roles! Like v-permission="[\'admin\',\'editor\']"')
//   }
// }

// export default {
//   inserted (el, binding) {
//     checkPermission(el, binding)
//   },
//   update (el, binding) {
//     checkPermission(el, binding)
//   }
// }

import store from '@/store/index'

export default {
  inserted (el, binding, vnode) {
    const { value } = binding
    const allPermission = '*:*:*'

    const permissions = store.getters['login/getPermission']

    if (value && value instanceof Array && value.length > 0) {
      const permissionFlag = value
      const hasPermissions = permissions.some(permission => {
        return allPermission === permission || permissionFlag.includes(permission)
      })

      if (!hasPermissions) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  }
}
