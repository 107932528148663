import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import promission from './modules/promission'
import header from './modules/header'
import activeRouter from './modules/activeRouter'
import dialog from './modules/dialog'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    login,
    promission,
    header,
    activeRouter,
    dialog
  }
})

export default store
