import { login, getUserInfo } from '@/api/login'

const state = {
  token: window.sessionStorage.getItem('token'),
  roles: [],
  permissionTable: []
}

const mutations = {
  /* 添加token */
  addToken (state, token) {
    state.token = token
  },
  /* 添加角色 */
  addRole (state, userRole) {
    state.roles = userRole
  },
  /* 移除token */
  removeToken (state) {
    window.sessionStorage.clear()
    state.roles = []
    location.reload()
  },
  // 添加权限
  addPermission (state, permission) {
    state.permissionTable = permission
  }
}

const actions = {
  /* 用户登录 */
  login ({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(res => {
        commit('addToken', res.token)
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  /* 获取用户个人信息 */
  getUserInfo ({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfo().then(res => {
        const roles = res.data.map((item) => item.roleName)
        const permission = res.data[0].menuList
        window.sessionStorage.setItem('roles', JSON.stringify(roles))
        commit('addRole', roles)
        commit('addPermission', permission)
        // console.log(permission)
        resolve(roles)
      }).catch(err => {
        reject(err)
      })
    })
  },
  /* 退出登录 */
  logout ({ commit }) {
    commit('removeToken')
  }
}

const getters = {
  /* 获取token */
  getToken (state) {
    return state.token
  },
  /* 获取角色 */
  getRoles (state) {
    return state.roles
  },
  // 获取权限表
  getPermission (state) {
    return state.permissionTable
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
