import axios from 'axios'
import { host } from './url'
import { Message } from 'element-ui'

/* 请求配置 */
const service = axios.create({
  baseURL: host,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8'
    // Authorization: window.sessionStorage.getItem('token')
  }
})

/* 请求前拦截 */
service.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  // Loading.service({ text: 'Loading...' })
  return config
}, error => {
  Message.error({ message: '请求超时!' })
  return Promise.reject(error)
})

/* 响应拦截 */
service.interceptors.response.use(response => {
  // Loading.service().close()
  const res = response.data
  if (res.returnCode !== 200) {
    Message.error({ message: res.message || '系统错误' })
    return Promise.reject(new Error('Error'))
  } else {
    return res
  }
}, error => {
  Message.error({ message: '账号不存在或密码错误' })
  return Promise.reject(error)
})

export default service
