/* 动态路由表 */
/* role对应路由的角色权限 */
const asyncRouter = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/components/index/index.vue'),
    meta: {
      title: '首页',
      role: ['超级管理员']
    }
  },
  {
    path: '/student/student',
    name: 'Sign',
    component: () => import('@/components/signUp/index.vue'),
    meta: {
      title: '报名列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/company/resource',
    name: 'Resource',
    component: () => import('@/components/company/index.vue'),
    meta: {
      title: '公司资源',
      role: ['超级管理员']
    }
  },
  {
    path: '/promoter/promoter',
    name: 'SaleUser',
    component: () => import('@/components/promoter/userList/index.vue'),
    meta: {
      title: '推广人员',
      role: ['超级管理员']
    }
  },
  {
    path: '/school/school',
    name: 'Scholl',
    component: () => import('@/components/college/school/index.vue'),
    meta: {
      title: '学校列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/school/major',
    name: 'Major',
    component: () => import('@/components/college/major/index.vue'),
    meta: {
      title: '专业列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/company/orders',
    name: 'Order',
    component: () => import('@/components/order/orderList/index.vue'),
    meta: {
      title: '报名订单',
      role: ['超级管理员']
    }
  },
  {
    path: '/company/signReadyPay',
    name: 'signReadyPay',
    component: () => import('@/components/order/orderList/ready.vue'),
    meta: {
      title: '报名预付信息',
      role: ['超级管理员']
    }
  },
  {
    path: '/company/learn',
    name: 'learnPay',
    component: () => import('@/components/order/learnPayList/index.vue'),
    meta: {
      title: '学费订单',
      role: ['超级管理员']
    }
  },
  {
    path: '/company/learnReadyPay',
    name: 'learnReadyPay',
    component: () => import('@/components/order/learnPayList/ready.vue'),
    meta: {
      title: '学费预付信息',
      role: ['超级管理员']
    }
  },
  {
    path: '/system/user',
    name: 'User',
    component: () => import('@/components/power/userList/index.vue'),
    meta: {
      title: '用户列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/system/role',
    name: 'Role',
    component: () => import('@/components/power/roleList/index.vue'),
    meta: {
      title: '角色列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/system/menu',
    name: 'Menu',
    component: () => import('@/components/power/menuList/index.vue'),
    meta: {
      title: '菜单列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/promoter/schoolTotal',
    name: 'schoolTotal',
    component: () => import('@/components/promoter/schoolTotal/index.vue'),
    meta: {
      title: '学校报名统计',
      role: ['超级管理员']
    }
  },
  {
    path: '/system/dict',
    name: 'Dict',
    component: () => import('@/components/paramList/index.vue'),
    meta: {
      title: '系统字典',
      role: ['超级管理员']
    }
  },
  { path: '*', redirect: '/404', hidden: true }
]

export default asyncRouter
